import React from 'react'
import {Link} from 'gatsby'
import styles from './Footer.module.sass'

const Footer = class extends React.Component {
    render() {
        return (
            <a href="mailTo:luciel.chloes@gmail.com" className={styles.footer}>
                <i className={styles.hoverHide}>•</i>
                <i className={styles.hoverShow}>↶•↷</i>
                <span>luciel.chloes@gmail.com</span>
            </a>
        )
    }
}

export default Footer
