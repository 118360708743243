import React, {Component} from 'react';
import ProjectItem from "./ProjectItem/ProjectItem";
import styles from './ProjectList.module.sass'

export default class ProjectList extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.projectItem = this.props.data.map((el) =>
            <ProjectItem project={el}/>
        );

    }

    render() {
        return (
            <div className={styles.scrollable}>
                {this.projectItem}
            </div>
        );
    }
}