import React, {Component} from 'react';
import {Link, navigate} from 'gatsby'
import styles from './ProjectItem.module.sass'
import classNames from 'classnames';

export default class ProjectItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };

        this.tagsList = this.props.project.node.frontmatter.tags.map((name,index) =>
            <span>{index !== 0 && ' + '}{name}</span>
        );

        this.toggleHover = this.toggleHover.bind(this);
    }

    toggleHover() {
        this.setState({
            hover: !this.state.hover
        })
    }

    render() {
        return (
            <div className={styles.projectLinkContainer}>
                <div className={classNames(styles.imgContainer,{[styles.active]: this.state.hover})}>
                    {this.props.project.node.frontmatter.images && this.props.project.node.frontmatter.images[0] && <img src={this.props.project.node.frontmatter.images[0].publicURL} alt=""/>}
                </div>
                <div onClick={() => {
                    navigate(this.props.project.node.fields.slug)
                }} className={styles.linkContainer} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                    <span><span style={{color: this.state.hover ? this.props.project.node.frontmatter.arrowColor : 'black'}}>→</span>  {this.props.project.node.frontmatter.title}</span>
                    <div className={styles.tagList}>~ {this.tagsList}</div>
                </div>
            </div>
        );
    }
}