import React from 'react'
import {Helmet} from 'react-helmet'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import '../all.sass'
import {withPrefix} from 'gatsby'
import styles from './Layout.module.sass'

const Layout = ({children, isIndex = false, isIndexPage = false, background = 'white'}) => {
    const bodyStyle = {
        background: background,
    };

    return (
        <div className={styles.body} style={bodyStyle}>
            <Helmet>
                <html lang="en"/>

                {/*<link*/}
                {/*    rel="mask-icon"*/}
                {/*    href={`${withPrefix('/')}img/safari-pinned-tab.svg`}*/}
                {/*    color="#ff4400"*/}
                {/*/>*/}
                <meta name="theme-color" content="#fff"/>

                {/*<meta property="og:type" content="business.business"/>*/}
                {/*<meta property="og:title" content="oui"/>*/}
                <meta property="og:url" content="/"/>
                {/*<meta*/}
                {/*    property="og:image"*/}
                {/*    content={`${withPrefix('/')}img/og-image.jpg`}*/}
                {/*/>*/}
            </Helmet>
            <Header isIndex={isIndex} isIndexPage={isIndexPage} background={background}/>
            <div className={styles.content}>{children}</div>
            <Footer/>
        </div>
    )
}

export default Layout
