import React from 'react'
import {Link, navigate} from 'gatsby'
import styles from './Header.module.sass'
import classNames from 'classnames';

const Header = class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
        this.onClickHandler = this.onClickHandler.bind(this);
    }

    onClickHandler(e) {
        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    render() {
        return (
            <header className={styles.header}>
                <h1 onClick={() => {
                    navigate("/")
                }}>cocktail</h1>
                {!this.props.isIndexPage &&
                <div className={styles.burgerIconContainer} onClick={this.onClickHandler}>
                    <div className={styles.burgerIcon}>
                        <i/>
                    </div>
                </div>
                }
                <div onClick={this.onClickHandler}
                     className={classNames({[styles.transparentBackground]: this.state.isOpen})}>
                </div>

                <ul style={{background: this.props.background}}
                    className={classNames({[styles.open]: this.state.isOpen}, {[styles.menu]: !this.props.isIndex})}>
                    <li className={classNames({[styles.hiddenMobile]: this.props.path !== "/projects" && !this.props.isIndex}, {[styles.first]: this.props.path === "/projects"})}>
                        <Link
                            to="/projects" className={classNames(styles.link, styles.project)}> projets</Link></li>
                    <li className={classNames({[styles.hiddenMobile]: this.props.path !== "/interventions" && !this.props.isIndex}, {[styles.first]: this.props.path === "/interventions"})}>
                        <Link
                            to="/interventions"
                            className={classNames(styles.link, styles.interventions)}> interventions</Link></li>
                    <li className={classNames({[styles.hiddenMobile]: this.props.path !== "/withWho" && !this.props.isIndex}, {[styles.first]: this.props.path === "/withWho"})}>
                        <Link
                            to="/withWho" className={classNames(styles.link, styles.withWho)}> avec qui</Link></li>
                    <li className={classNames({[styles.hiddenMobile]: this.props.path == "/about" && !this.props.isIndex}, {[styles.first]: this.props.path === "/about"})}>
                        <Link
                            className={classNames(styles.link, styles.about)}
                            to="/about"> à propos</Link>
                    </li>
                </ul>
            </header>
        )
    }
}

export default Header
