import React from 'react'
import {Link, navigate} from 'gatsby'
import styles from './LayoutWithMenu.module.sass'
import classNames from 'classnames';

const LayoutWithMenu = class extends React.Component {
    constructor(props) {
        super(props);
        this.path = this.props.path
        if (this.path && this.path.endsWith('/')) {
            this.path = this.path.slice(0, -1);
        }
    }

    render() {
        return (
            <div className={styles.bodyWithHeader}>
                <ul>
                    <li className={classNames({[styles.hiddenMobile]: this.path !== "/projects" && !this.props.isIndex},{[styles.first]: this.path === "/projects"})}><Link
                         to="/projects" className={classNames(styles.link, styles.project)}>  projets</Link></li>
                    <li className={classNames({[styles.hiddenMobile]: this.path !== "/interventions" && !this.props.isIndex},{[styles.first]: this.path === "/interventions"})}><Link
                        to="/interventions" className={classNames(styles.link, styles.interventions)}>  interventions</Link></li>
                    <li className={classNames(styles.intro,{[styles.second]: !this.props.isIndex})}>
                        {this.props.children}
                    </li>
                    <li className={classNames({[styles.hiddenMobile]: this.path !== "/withWho" && !this.props.isIndex}, {[styles.first]: this.path === "/withWho"})}><Link
                        to="/withWho" className={classNames(styles.link, styles.withWho)}>  avec qui</Link></li>
                    <li className={classNames({[styles.hiddenMobile]: this.path !== "/about" && !this.props.isIndex}, {[styles.first]: this.path === "/about"})}><Link
                        className={classNames(styles.link, styles.about)}
                        to="/about">  à propos</Link>
                    </li>
                </ul>
            </div>
        )
    }
}

export default LayoutWithMenu
